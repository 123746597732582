<template>
  <b-container v-if="rules">
    <b-col
      offset-lg="1"
      lg="10"
      offset-sm="0"
      sm="12"
    >
      <h3
        v-if="appText('rules_page_title')"
        class="text-center mb-5"
      >
        {{ appText('rules_page_title') }}
      </h3>
      <div
        class="accordion"
        role="tablist"
        @languageChange="handleLangChange"
      >
        <template v-for="(rule, index) in rules.instruction">
          <b-card
            :key="index"
            no-body
            class="mb-1"
            :class="[
              {isMobile}
            ]"
          >
            <b-card-header
              v-b-toggle="'accordion-' + index"
              header-tag="header"
              class="p-1 hubu"
              role="tab"
            >
              {{ rule.name }}
            </b-card-header>
            <b-collapse
              :id="'accordion-' + index"
              :visible="index==0"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text v-html="rule.content">
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </template>
      </div>
    </b-col>
  </b-container>
</template>

<script>
import Cookies from "js-cookie";
import eventBus from "./Helpers/eventBus";
export default {
  name: "Faq",

  data () {
    return {
      instructions: null,
    };
  },

  computed: {
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    rules: {
      get () {
        return this.instructions;
      },
      set (v) {
        this.instructions = v;
      },
    },
  },

  beforeMount () {
    eventBus.$on("languageChange", (locale) => {
      this.$i18n.locale = locale;
      this.$store.dispatch("getInstructions").then((resp) => {
        this.rules = resp;
      });
    });
    this.$store.dispatch("getInstructions").then((resp) => {
      this.rules = resp;
    });
  },

  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    handleLangChange () {
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu {
  background-color: $red;
  color: $white;
  text-align: left;
  padding-left: 20px !important;
  font-size: 18px;
  cursor: pointer;
}

.isMobile {

  .hubu {
    font-size: 4vw !important;
  }
}
</style>